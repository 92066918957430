<template>
	<div class="p-3 py-4" style="max-height: 100vh; overflow-y: auto;">
		<div class="close-btn" v-on:click="$emit('close',{})">
			<font-awesome-icon class="m-auto" icon="times" />
		</div>


		<div class="add-modal-body">
			<h4>{{ $t('planes.addPlane') }}</h4>
			<div class="search-input">
				<font-awesome-icon icon="search" />
				<input type="text" v-model="search" :placeholder="$t('planes.search')" @keyup="onSearch" />
			</div>
			<h6 class="mt-2 mb-0">
				<b>{{filtered.length}}</b> {{$t('planes.planesFound')}}
			</h6>
			<div class="planes">
				<plane-card :horizontal="true" :plane="plane" v-for="plane in filtered" v-bind:key="plane.id" @click="onAdd(plane)">
					<button class="btn btn-dark" @click.stop="onCreate(plane)" v-b-tooltip.hover.top="$t('planes.copy')">
						<font-awesome-icon class="m-0" :icon="['fas', 'copy']" />
					</button>
					<button class="btn btn-primary" @click.stop="onAdd(plane)" v-b-tooltip.hover.top="$t('planes.join')">
						<font-awesome-icon class="m-0" :icon="['fas', 'plus-circle']" />
					</button>
				</plane-card>
			</div>
			<div class="modal-actions">
				<button class="btn btn-primary" @click="onCreate(null)">
					<font-awesome-icon class="m-0" :icon="['fas', 'plus']" />
					{{$t('planes.createNew')}}
				</button>
				<button class="btn btn-secondary" @click="$emit('close',{})">
					<font-awesome-icon class="m-0" :icon="['fas', 'times']" />
					{{$t('planes.close')}}
				</button>
			</div>
		</div>

	</div>
</template>

<script>
import { getPlaneIcon } from "@/plugins/planeUtils";
import Fuse from 'fuse.js';
import PlaneCard from "@/components/plane/PlaneCard.vue";

export default {
	name: 'AddPlaneModel',
	components: { PlaneCard },
	emits: ['close'],
	data() {
		return {
			/** @type {Plane[]} */
			planes: [],
			/** @type {string} */
			search: "",
			/** @type {Fuse<Plane>} */
			fuse: null,
			/** @type {Plane[]} */
			filtered: [],
			searchTimeout: null,
		}
	},
	methods: {
		getIcon(plane) {
			return getPlaneIcon(plane);
		},
		onSearch() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}
			this.searchTimeout = setTimeout(() => {
				this.searchTimeout = null;
				this.doSearch();
			}, 300);
		},
		doSearch() {
			if (!this.search) {
				this.filtered = this.planes;
				return;
			}
			this.filtered = this.fuse.search(this.search).map(result => result.item);
		},

		/** @param {Plane} plane */
		async onAdd(plane){
			/** @type {boolean} */
			const r = await this.$rest.attachToPlane(plane.id)
			window.newEvent("ADD_PLANE", {
				planeId: plane.id
			})
			if (r) {
				this.$emit('close', {});
			}
		},

		/** @param {Plane|null} plane */
		async onCreate(plane){
			this.$emit('close', {});
			window.newEvent("NEW_PLANE", {
				fromId: plane ? plane.id : null
			})
			await this.$router.replace({
				name: 'planeCreate',
				query: {
					from: plane ? plane.id : null
				}
			})
		}
	},
	async mounted() {
		this.planes = (await this.$rest.loadAllPlanes()) || [];
		this.fuse = new Fuse(this.planes, {
			keys: [
				{
					name: 'registrationNumber',
					weight: 2
				},
				{
					name: 'producer',
					weight: 1
				},
				{
					name: 'model',
					weight: 1.5
				},
				{
					name: 'planeModel',
					weight: 1.5
				},
				{
					name: 'colorAndNotation',
					weight: 0.5
				}
			],
			threshold: 0.4,
		});
		this.doSearch();
	}
}
</script>

<style scoped>
.add-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}


.search-input {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 8px;
	margin-top: 0.6rem;
	padding: 0.5rem 1rem;
	width: 100%;
	border: 1px solid #97a3a4;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
}

.search-input input {
	border: none;
	background-color: transparent;
	width: 100%;
	padding: 0.5rem 1rem;
}


.planes {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 400px;
	padding: 0.5rem;
	overflow-y: auto;
	overflow-x: hidden;
	gap: 0.5rem;
}

.close-btn {
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.modal-actions {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
}

</style>
